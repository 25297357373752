.quantity-block {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f8f8f8;
  width: fit-content;
  padding: 6px;
}

.quantity-block input {
  width: 35px;
  text-align: center;
  border: none;
  font-size: 16px;
  padding: 6px;
  background-color: #fff;
  color: #333;
}

.quantity-block button {
  width: 30px;
  height: 30px;
  background-color: #A8895B;
  color: #fff;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.quantity-block button:hover {
  background-color: #7f6743;
}

.quantity-block button:active {
  background-color: #7f6743;
}

.descricao-compras {
  width: 100%;
  font-size: 20px;
  padding: 20px;
  background-color: #FFF;
  color: #A8895B;
  margin-top: 20px;
}

.header-component-compras {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
  background-color: #053114;
  position: fixed;
  z-index: 1000;
}

.btnCadastro-compras, .btnLogar-compras {
  border: none;
  border-radius: 15px;
  background-color: #A8895B;
  padding: 10px 20px;
  color: #800020;
  cursor: pointer;
  margin: 0 10px;
}

.btnCadastro-compras:hover, .btnLogar-compras:hover {
  background-color: #C0A36E;
}

.content-compras {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: auto;
  padding: 20px 0;
  z-index: 500;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.produto-container-compras {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.5);
  padding: 20px;
  margin-top: 150px;
  max-width: 900px;
  width: 100%;
}

.produto-info-compras {
  width: 100%;
  margin-top: 20px;
}

.valores {
  display: grid;
  align-items: center;
}

.produto-imagem-compras {
  width: 50%;
  height: auto;
  border-radius: 15px;
  margin-right: 200px;
}

.produto-info-compras h1 {
  font-size: 28px;
  color: #053114;
  margin-bottom: 10px;
}

.preco-compras {
  font-size: 24px;
  color: #800020;
  margin-bottom: 10px;
}

.preco-parcelado-compras {
  font-size: 18px;
  color: #A8895B;
  margin-bottom: 25px;
}

.frete-compras {
  font-size: 16px;
  color: #053114;
  margin-bottom: 20px;
}

.btnComprar-compras {
  border: none;
  border-radius: 15px;
  background-color: #467459;
  padding: 15px 30px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  margin-top: 25px;
}

.btnComprar-compras:hover {
  background-color: #d3a123;
}

.footer-component-compras {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100%;
  background-color: #053114;
  color: #A8895B;
  margin-top: auto;
}

.quemSomos {
  width: 85%;
  font-size: 18px;
  padding: 20px;
  background-color: #FFF;
  color: #A8895B;
  margin-top: 20px;
}

.faq-section {
  margin-top: 20px;
  width: 80%;
  margin: 0 auto;
  padding-top: 20px;
}

.faq-section h2{
  margin-bottom: 10px;
}

.faq-item {
  margin-bottom: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  border: none;
}

.faq-question {
  font-size: 18px;
  color: #053114;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 3px;
  border: none;
}

.faq-answer {
  font-size: 16px;
  color: #800020;
  padding: 10px;
  background-color: #ffffff;
  border: none;
}

@media (max-width: 600px) {
  .faq-section {
    width: 100%;
  }

  .faq-question, .faq-answer {
    padding: 8px;
    font-size: 16px;
  }

  .logo-compras {
    height: 200px;
    position: relative;
  }

  .btnCadastro-compras, .btnLogar-compras {
    width: auto;
    padding: 8px 12px;
    font-size: 12px;
  }

  .produto-container-compras {
    flex-direction: column;
  }

  .produto-imagem-compras,
  .produto-info-compras {
    width: 100%;
    margin-bottom: 20px;
  }

  .produto-info-compras {
    width: 100%;
    margin-top: 20px;
  }

  .descricao-compras {
    width: 100%;
    font-size: 20px;
    padding: 20px;
    background-color: #FFF;
    color: #A8895B;
    margin-top: 20px;
  }

  .produto-info-compras h1 {
    font-size: 20px;
  }

  .preco-compras {
    font-size: 16px;
  }

  .preco-parcelado-compras {
    font-size: 14px;
  }

  .frete-compras {
    font-size: 12px;
  }

  .btnComprar-compras {
    padding: 14px 20px;
    font-size: 17px;
    display: grid;
    align-items: center;
  }

  .quemSomos {
    font-size: 16px;
    width: 100%;
    font-size: 18px;
    padding: 0px;
    background-color: #FFF;
    color: #A8895B;
    margin-top: 25px;
  }
}