.container-cadastro {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #c4c2bf;
}

.container-cadastro h2{
  color: #d8ca87;
}

.container-cadastro label{
  color: #d8ca87;
}

.header-component-cadastro {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
  background-color: #053114;
  position: fixed;
}

.logo-cadastro {
  height: 400px;
  max-width: 100%;
  position: fixed;
}

.content-cadastro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  padding-top: 180px; 
}

.form-container-cadastro {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70%;
  background-color: #053114;
  color: #A8895B;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.5);
}

.form-container-cadastro h2 {
  margin-bottom: 20px;
}

.form-container-cadastro label {
  margin: 10px 0 5px;
  width: 100%;
}

.form-container-cadastro input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #A8895B;
}

.form-container-cadastro button {
  border: none;
  border-radius: 10px;
  padding: 10px;
  background-color: #d8ca87;
  color: #000;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  display: block;
  margin: 15px 0;
}

.form-container-cadastro button:hover {
  background-color: #e7b539;
}

.footer-component-cadastro {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  background-color: #053114;
  color: #A8895B;
  margin-top: 20px;
}

.btnCadastro-cadastro {
  border: none;
  border-radius: 15px;
  display: flex;
  margin-left: 80%;
  background-color: #C0A36E;
  padding: 0.5%;
  color: #800020;
  cursor: pointer;
}

.btnLogar-cadastro {
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #C0A36E;
  padding: 0.5%;
  margin: 4%;
  width: 5%;
  color: #800020;
  text-align: center;
  cursor: pointer;
}

.btnCadastro-cadastro {
  background-color: #A8895B;
}

.btnLogar-cadastro {
  background-color: #A8895B;
}

@media only screen and (max-width: 768px) {
  .header-component-cadastro {
      height: 120px;
  }

  .logo-cadastro {
      height: 200px;
      position: relative;
  }

  .btnCadastro-cadastro, .btnLogar-cadastro {
      margin: 0 auto;
      width: 20%;
      font-size: 14px;
      padding: 10px;
  }

  .content-cadastro {
      width: 80%;
      padding-top: 150px;
  }

  .form-container-cadastro {
      width: 100%;
      padding: 15px;
      margin-top: 50px;
  }

  .form-container-cadastro input {
      font-size: 14px;
      padding: 10px;
  }

  .form-container-cadastro button {
    font-size: 15px;
    padding: 10px 12px;
  }

  .footer-component-cadastro {
      height: 100px;
      text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .header-component-cadastro {
      height: 100px;
  }

  .logo-cadastro {
      height: 150px;
      position: relative;
  }

  .btnCadastro-cadastro, .btnLogar-cadastro {
      margin: 10px 0;
  
      font-size: 12px;
      padding: 3px;
  }

  .content-cadastro {
      width: 90%;
      padding-top: 120px;
  }

  .form-container-cadastro {
      width: 100%;
      padding: 10px;
  }

  .footer-component-cadastro {
      height: 80px;
      text-align: center;
  }
}
