.header-component-home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
  background-color: #467459;
  position: fixed;
  z-index: 1000;
}
.logo-header{
  position: fixed;
  margin-top: 30px;
}

.logo {
  height: 100px;
  max-width: 100%;
}

.btnCadastro {
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0.5%;
  margin: 4%;
  width: 5%;
  color: #800020;
  text-align: center;
  cursor: pointer;
}

.btnLogar {
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  padding: 0.5%;
  margin: 4%;
  width: 5%;
  color: #800020;
  text-align: center;
  cursor: pointer;
}

.btnCart {
  border: none;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #C0A36E;
  padding: 0.5%;
  margin: 4%;
  width: 5%;
  color: #800020;
  text-align: center;
  cursor: pointer;
}

.btnCadastro {
  background-color: #A8895B;
}

.btnLogar {
  background-color: #A8895B;
}

@media only screen and (max-width: 600px) {

  .btns {
    display: none;
  }

  .header-component-home {
    height: 120px;
  }

  .logo {
    height: 80px;
    position: relative;
    display: grid;
    align-items: center;
  }

  .btnCadastro,
  .btnLogar {
    display: grid;
    align-items: center;
    width: 50%;
    margin: 10px 0;
    font-size: 13px;
    padding: 8px;
    display: none
  }
}