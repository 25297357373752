.container-pedidos{
  min-height: 70vh;
  padding-top: 20vh;
}

.p-datatable * {
  margin: 0;
}

.p-datatable {
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 90%;
}

.p-datatable .p-datatable-tbody > tr > td {
  padding: 10px;
}

.p-datatable .p-datatable-thead > tr > th {
  background-color: #f0f0f0;
  padding: 10px;
}

.p-datatable .p-datatable-tfoot > tr > td {
  background-color: #f0f0f0;
  padding: 10px;
}

.p-paginator {
  margin: 10px 0;
  padding: 10px 0;
}

.p-paginator .p-paginator-pages {
  margin: 0;
  padding: 0;
  list-style: none;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  display: inline-block;
  margin-right: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-paginator-page-active {
  background-color: #337ab7;
  color: #fff;
}

.p-input-icon-right {
  padding: 10px;
  border-radius: 5px;
}

.p-input-icon-right .pi.pi-search {
  margin-right: 10px;
}

.p-input-icon-right input{
  padding: 10px;
}

.p-input-icon-right {
  position: relative;
  display: inline-block;
}

.p-input-icon-right .pi.pi-search {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.p-input-icon-right input {
  padding-right: 30px; 
}

.p-dropdown{
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .p-datatable {
    width: 100%;
    padding: 10px;
  }
  .p-inputtext {
    width: 100%;
    box-sizing: border-box; 
  }
}

.textAcesso{
  text-align: center;
}

.p-button.p-button-info{
  background: #467459;
  border: 0;
  padding: 6px;
  box-shadow: none;
  width: 100%;
  display: block;
}

.show-dialog * {
  margin: 0;
  padding: revert-layer;
}

.p-dialog-title{
  margin: 10px;
}

.show-dialog p{
  margin: 10px;
} 

@media screen and (max-width: 600px) {
  .show-dialog {
    margin: 10px;
  }
}

