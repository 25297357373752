.frete-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #d3a123;
  color: white;
  border-radius: 10px;
  padding: 10px 0;
  margin-left: 0%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  z-index: 10000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  font-family: 'Open Sans', sans-serif;
}

.frete-banner {
  display: inline-block;
  white-space: nowrap;
}

.frete-banner span {
  display: inline-block;
  font-family: 'Open Sans', sans-serif;
  animation: fadeIn 1s forwards;
  margin-right: 2px;
}

.frete-banner .empty-span {
  margin-right: 4px;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .frete-banner {
    display: grid;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #d3a123;
    ;
    color: white;
    border-radius: 10px;
    padding: 10px 0;
    text-align: center;
    font-size: 12px;
    font-family: 'Open Sans', sans-serif;
    font-weight: bold;
    z-index: 10000;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

  .frete-banner {
    display: inline-block;
    white-space: nowrap;
  }

  .frete-banner span {
    opacity: 0;
    display: inline-block;
    margin-right: 2px;
    animation: fadeIn 0.5s forwards;
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
}