.container-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #c4c2bf;
}

.container-login h2{ 
  margin-bottom: 25px;
}

.header-component-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%;
  padding: 0 10px; 
  background-color: #053114;
  position: fixed;
}

.logo-login {
  height: 400px;
  max-width: 100%;
  position: fixed;;
}

#btn-cadastrar{
  border: none;
  background-color: #d8ca87;
}

.btnCadastro-login{
  border: none;
  border-radius: 15px;
  display: flex;
  margin-left: 80%;
  background-color: #d8ca87;
  padding: 0.5%;
  color: #000;
  cursor: pointer;
}
.btnCadastro-login {
  margin-right: 10px;
}

.content-login {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 180px; 
  height: calc(100vh - 150px);
  width: 100%;
}

.form-container-login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 400px; 
  padding: 20px;
  background-color: #053114;
  color: #A8895B;
  border-radius: 15px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.5);
}

#formLogin {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#formLogin label {
  margin: 0;
}

#formLogin input {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
}

#btnLogar-login {
  border: none;
  border-radius: 10px;
  padding: 10px;
  background-color: #d8ca87;
  color: #000;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}


#btnCadastrar-login{
  border: none;
  border-radius: 10px;
  padding: 10px;
  background-color: #d8ca87;
  color: #000;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
}


#btn-cadastrar{
  border: none;
  border-radius: 10px;
  background-color: #d8ca87;
  color: #000;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  text-align: center;
  margin-top: 5px;
  margin-bottom: 20px;
}

.footer-component-login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  width: 100%;
  background-color: #053114;
  color: #A8895B;
  margin-top: 20px;
}

.content-login::-webkit-scrollbar {
  width: 12px;
}

.form-container-login h2{
  color: #d8ca87;
}

.form-container-login label{
  color: #d8ca87;
}

#password {
  margin-bottom: 25px;
}

.content-login::-webkit-scrollbar-track {
  background: #000;
  border-radius: 10px;
}

.content-login::-webkit-scrollbar-thumb {
  background-color: #d8ca87;
  border-radius: 10px;
  border: 3px solid #000;
}

@media only screen and (max-width: 600px) {
  .header-component-login {
    height: 120px;
  }

  .logo-login {
    height: 200px;
    position: relative; 
    }

  .content-login {
    padding-top: 140px;
    height: auto;
    height: 500px;
  }

  .form-container-login {
    width: 90%;
    padding: 15px;
    margin-top: 50px;
  }

  .footer-component-login {
    height: 200px;
    flex-direction: column;
    text-align: center;
  }

  .content-login::-webkit-scrollbar {
    width: 8px;
  }
}
