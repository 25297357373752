.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  margin-top: 7.2%;
  height: 110%;
  background-color: #467459;
  color: #fff;
  transition: left 0.3s ease;
  z-index: 2000;
  border-collapse: collapse;
}

.loginUser span{
  font-size: 16px !important;
}

.sidebar.open {
  left: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin-top: 60px;
}

.sidebar ul li {
  padding: 15px 20px;
}

.sidebar ul li:hover {
  background-color: #575757;
}

.toggle-button {
  position: absolute;
  top: 20px;
  right: -30px;
  width: 40px;
  height: 40px;
  margin-top: -50px;
  background-color: #467459;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 50%;
  border: none;
}

.toggle-button:hover {
  background-color: #575757;
}

.btnCadastroMenu {
  background-color: #A8895B;
  display: none;

}

.btnLogarMenu {
  background-color: #A8895B;
  display: none;
}


#linkMenu {
  text-decoration: none;
  color: #fff;
}


#linkConta {
  border-radius: 4px;
  text-decoration: none;
  color: #fff;
  font-size: 20px;
  display: grid;
  align-items: center;
  text-align: center;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: -250px;
    width: 250px;
    margin-top: 30%;
    height: 100%;
    background-color: #467459;
    color: #333;
    transition: left 0.3s ease;
    z-index: 2000;
    border-collapse: collapse;

  }

  .sidebar.open {
    left: 0;
  }

  .sidebar ul {
    list-style-type: none;
    padding: 0;
    margin-top: 60px;
  }

  .sidebar ul li {
    padding: 15px 20px;
  }

  .sidebar ul li:hover {
    background-color: #575757;
  }

  .toggle-button {
    position: absolute;
    top: 20px;
    right: -30px;
    width: 30px;
    height: 30px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
  }

  .toggle-button:hover {
    background-color: #575757;
  }

  .btnCadastroMenu,
  .btnLogarMenu {
    display: grid;
    align-items: center;
    width: 35%;
    margin: 10px 0;
    font-size: 13px;
    padding: 8px;
    margin-left: 10px;
    border-radius: 5px;
    border: none;
  }

  .btnMenu {
    padding-top: 200px;
  }
}