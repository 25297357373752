.carousel-container {
    max-width: 450px;
    margin: 20px auto;

}

#imgCarrossel{
    width: 50px;
    height: 40px;
}


@media only screen and (max-width: 600px) {
    .carousel-container {
        max-width: 70%;
    }
}
