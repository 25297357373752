.section-sac {
  background-color: initial;
  display: flex;
  flex-direction: column;
  min-height: 55vh;
  padding-top: 10vh;
}

.title-sac {
  text-align: center;
  margin-bottom: 40px;
  color: #333;
}

.sac-contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 40px;
}

.sac-contact p {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.sac-contact p svg {
  font-size: 30px;
  display: block;
  margin: 0 auto;
}

.sac-contact p:hover {
  color: #333;
}

.sac-contact a {
  text-decoration: none;
}

.sac-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 25px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}

.sac-info-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  padding: 20px;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  max-width: 330px;
}

.sac-icon {
  font-size: 30px;
  margin-right: 15px;
}

.sac-info-item h4 {
  font-size: 15px;
  margin-bottom: 5px;
}

.sac-info-item p {
  font-size: 14px;
  color: #333;
}

@media (max-width: 600px) {
  .section-sac {
    padding-top: 1vh;
  }

  .sac-info {
    flex-direction: column;
  }

  .title-sac h3 {
    font-size: 16px;
    padding: 0 10px;
  }

  .sac-contact p {
    font-size: 16px;
  }

  .sac-info-item p {
    font-size: 12px;
  }

  .sac-icon {
    font-size: 24px;
  }
}