#text1 {
  font-size: 2rem; 
  font-weight: 700; 
  color: #333;
  line-height: 1.5;
  text-align: center; 
  margin: 20px 0; 
}

.content-home {
  display: grid;
  grid-template-columns: 1fr; 
  align-items: center;
  padding-top: 150px;
  width: 100%;
  row-gap: 10px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.blocoProduto {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
}

.produto {
  max-width: 100%;
  height: auto;
  position: relative;
  border-radius: 5px;
}
.btnProd {
  max-width: 50%;
  display: grid;
  align-items: center;
  height: auto;
  position: relative;
  border-radius: 5px;
}
.triggersVendas {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.btnVenda {
  background-color: #467459;
  padding: 15px 55px;
  border: none;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  margin-left: 0%;
}

.footer-component-home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 100%; 
  background-color: #053114;
  margin-top: 20px;
}

.footer-icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
}

.footer-icon {
  font-size: 24px;
  color: #333;
}

.content-home::-webkit-scrollbar {
  width: 12px;
}

.content-home::-webkit-scrollbar-track {
  background: #800020; 
  border-radius: 10px;
}

.content-home::-webkit-scrollbar-thumb {
  background-color: #C0A36E;
  border-radius: 10px;
  border: 3px solid #800020; 
}

#btnComprar{
  background-color: #d3a123;
  border: none;
  padding: 15px 50px;
  border: none;
  border-radius: 15px;
  color: #053114;
  cursor: pointer;
  font-size: 16px;
  margin-left: 100%;

}

.email-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 400px;
  margin: 0 auto;
  background-color: transparent;
  border: none; 
}

.email-label {
  font-size: 18px;
  margin-bottom: 10px;
  border-radius: 4px; 
  color: #467459; 
  font-weight: bold;
}

.input-wrapper {
  display: flex;
  flex-direction: column; 
  width: 100%;
  margin-bottom: 15px; 
}

.email-input {
  padding: 10px 15px;
  font-size: 16px;
  border: 1px solid #d0d0d0; 
  border-radius: 5px;
  outline: none;
  margin-bottom: 10px; 
}

.email-input:focus {
  border-color: #467459; 
}

.email-submit-button {
  padding: 10px 20px;
  border-radius: 4px; 
  font-size: 16px;
  background-color: #467459; 
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  width: 62.9%;
}

.email-submit-button:hover {
  background-color: #365a43; 
}

.email-submit-button:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(70, 116, 89, 0.5);
}

.titulo-sobre {
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  text-align: center;
  font-weight: bolder;
  color: #333;
}

.sobre-nos {
  width: 60%;
  font-size: 20px;
  font-family: 'Open Sans', sans-serif;
  text-align: justify;
  color: #555;
  line-height: 1.8;
  padding-top: 40px;
}

.texto-sobre {
  font-family: 'Open Sans', sans-serif;
  text-align: justify;
  font-size: 18px;
  color: #777;
  margin-bottom: 20px;
}

.texto-sobre p{
  margin: 5px 0;
}

@media only screen and (max-width: 600px) {
  .btnCadastro, .btnLogar {
    display: grid;
    align-items: center;
    width: 50%;
    margin: 10px 0; 
    font-size: 13px; 
    padding: 8px; 
  }

  .content-home {
    grid-template-columns: 1fr; 
      height: 100%; 
  }

  .blocoProduto {
    width: 100%; 
    display: flex;
    justify-content: center; 
  }

  .produto {
    margin-top: -29px;
    width: 100%; 
    height: auto; 
 }

  .btnVenda {
    background-color: #d3a123;
    border: none;
    padding: 15px 50px;
    border: none;
    border-radius: 15px;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    margin-left: 0%;
  }

  .footer-component-home {
    height: 120px; 
    flex-direction: column; 
    text-align: center; 
  }

  .content-home::-webkit-scrollbar {
    width: 8px; 
  }

  #text1 {
    font-size: 1.0rem; 
    font-weight: 700; 
    color: #333; 
    line-height: 1.5; 
    text-align: center; 
    margin: 20px 0; 
  }
  .sobre-nos{
    width: 70%;
    font-size: 15px;

  }
  .active-text{
    width: 50%;
  }  
}