.container-pagamento {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
  background-color: transparent;
  padding-top: 20vh;
}

.pagamento-content {
  padding: 20px;
  width: 555px;
  margin: 20px auto;
  background-color: transparent;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.pagamento-content h1 {
  color: #467459;
  margin-bottom: 20px;
  font-size: 27px;
}

.payment-options {
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.payment-button {
  padding: 10px 20px;
  font-size: 16px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  background-color: #f0f0f0;
  color: #467459;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.payment-button.active {
  background-color: #467459;
  color: #fff;
}

.payment-button:hover {
  background-color: #365a43;
  color: #fff;
}

.payment-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
}

.payment-form label {
  font-size: 14px;
  color: #467459;
  margin-bottom: 5px;
}

.payment-form input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
}

.payment-form input:focus {
  border-color: #467459;
}

.pix-qr-code {
  width: 200px;
  height: 200px;
  background-color: transparent;
  margin: 20px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

.generate-boleto-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  background-color: transparent;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.generate-boleto-button:hover {
  background-color: #365a43;
}

.payment-method {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}

.payment-method input[type="radio"] {
  margin-right: 10px;
}

.payment-method label {
  font-size: 16px;
}

.payment-method .no-fee {
  color: #00a000;
  font-size: 14px;
}

.card-icons img {
  width: 40px;
  margin-right: 5px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-size: 15px;
  margin-bottom: 5px;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-size: 14px;
}

.form-group .half-width {
  width: calc(50% - 10px);
  display: inline-block;
}

.form-group .half-width:first-child {
  margin-right: 20px;
}

.form-group .security-code {
  display: flex;
  align-items: center;
}

.form-group .security-code input {
  flex: 1;
}

.form-group .security-code i {
  margin-left: 10px;
  color: #888;
}

.submit-button {
  background-color: #467459;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 15px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.submit-button i {
  margin-right: 10px;
}

.hidden {
  display: none;
}

.pix-details h2 {
  font-size: 18px;
  margin: 20px 0;
}

.svelte-12nsdgl {
  margin-bottom: 10px;
  font-size: 16px;
}

.swal2-popup {
  width: 26em;
  margin-bottom: 20%;
}

.swal2-html-container ul {
  list-style: none;
  padding: 0;
}

.swal2-actions button {
  background-color: #467459;
}

#cardPaymentBrick_container * {
  margin: 0 !important;
}

#statusScreenBrick_container section>div * {
  margin: 0 !important;
}

#statusScreenBrick_container span,
#statusScreenBrick_container p {
  padding-left: 15px !important;
}

#cardPaymentBrick_container h2 {
  margin-bottom: 13px !important;
}

#cardPaymentBrick_container label {
  margin-bottom: 5px !important;
}

#cardPaymentBrick_container option[value="CNPJ"] {
  display: none !important;
}

.pix-details{
  padding: 10px;
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.opcoes-frete .form-group {
  margin: 10px;
}

.opcoes-frete h2 {
  margin: 10px 0;
  font-size: 18px;
}

.opcoes-frete div {
  margin-bottom: 10px;
}

.opcoes-frete label {
  font-size: 18px;
  font-weight: 100;
}

.mt-10 {
  margin: 5px;
}

.mt-10 input {
  padding: 15px;
}

.top-voltar {
  margin-top: 15px;
}

.pedidos {
  margin-top: 15px;
}

.textAcesso {
  text-align: center;
}

.opcoes-frete{
  margin-top: 20px;
}

@media screen and (max-width: 600px) {
  .pagamento-content{
    width: 90%;
  }

  .pagamento-content h1 {
    font-size: 20px;
  }
  .opcoes-frete h2{
    font-size: 16px;
  }
  .opcoes-frete label{
    font-size: 16px;
  }
  .pix-details h2 {
    font-size: 16px;
  }
}