.footer-component-css {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  background-color: #467459;
  color: #fff;
  font-size: 18px;
  width: 100%;
  position: relative;
  margin-top: 100px;
}

.footer-component-css svg path{
  color: #fff;
}

.footer-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.footer-icon {
  font-size: 30px;
  margin-right: 20px;
}


.payment-icons {
  display: flex;
  gap: 10px;
}

#company-info {
  text-align: center;
  font-size: 16px;
  line-height: 1.5;
}

.security-section {
  display: flex;
  margin-top: 50px;
  text-align: center;
  gap: 30px;
  font-size: 18px;
  padding-left: 70px;
}

.payment-security, .site-security {
  margin-bottom: 20px;
}

.payment-methods, .security-icons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 5px;
}

@media only screen and (max-width: 600px) {
  .footer-component-css {
    position: relative;
    padding: 20px;
    text-align: center;
  }

  .footer-icons {
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
  }

  .footer-icon {
    margin: 10px;
  }

  .payment-icons {
    flex-direction: row;
  }

  #company-info {
    font-size: 14px;
  }

  .security-section {
    margin-top: 0px;
    font-size: 20px;
    padding-left: 0;
    margin-bottom: 60px;
  }

  .security-section p {
    font-size: 14px;
  }
}
